import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { JWT_TOKEN_KEY_NAME, LOGIN_USER_MODE } from 'constants/common'
import jwtDecode from 'jwt-decode'

const Logo = styled.img`
  height: 40px;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    height: 40px;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
`
const LoginUserMode = styled.div`
  font-size: 10px;
  font-weight: 600;
  color: ${(p) => p.theme.BLACK};
  text-decoration: none;
  text-align: center;
`

const TopLogo = ({ to }: { to?: string }) => {
  const loginUserMode = localStorage.getItem(LOGIN_USER_MODE)
  if (!to) {
    return <Logo src="/image/terass-portal_logo.svg" alt="logo" />
  }

  const token = localStorage.getItem(JWT_TOKEN_KEY_NAME)
  const { email } = token ? (jwtDecode(token) as any) : ''

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <StyledLink to={to}>
        <Logo src="/image/terass-portal_logo.svg" alt="logo" />
      </StyledLink>
      <div>
        {loginUserMode === 'impersonation' && (
          <LoginUserMode>【{email}】で代理ログイン中</LoginUserMode>
        )}
      </div>
    </div>
  )
}
export default TopLogo
