import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { HeaderContainer } from 'components/header'
import { StrongText, Text } from 'components/typography'
import { useAuthProvider } from 'contexts/auth'
import { Contract } from 'schema/contract'
import { UserRole } from 'schema/user'
import { LoginUserModeEnum } from 'constants/loginusermode'
import { LOGIN_USER_MODE } from 'constants/common'

const Container = styled(HeaderContainer)`
  justify-content: flex-start;
  padding: 0 30px;

  ${Text},
  ${StrongText} {
    display: block;
  }

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    padding: 0 20px;

    > * {
      height: max-content;
    }
  }
`

const Back = styled.button`
  width: 25px;
  height: 30px;
  margin-right: 20px;
  background-color: transparent;
  background-image: url('/image/back-page.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    width: 10px;
    height: 15px;
  }
`

const ChatHeader = ({ contract }: { contract?: Contract }) => {
  const navigate = useNavigate()
  const { claims } = useAuthProvider()
  const loginUserMode = localStorage.getItem(
    LOGIN_USER_MODE
  ) as LoginUserModeEnum

  return (
    <Container lum={loginUserMode}>
      <Back onClick={() => navigate(-1)} />
      <div>
        <StrongText size="sm">{contract?.name}</StrongText>
        {claims?.role === UserRole.AGENT && (
          <Text size="md">{contract?.ownerName} さん</Text>
        )}
      </div>
    </Container>
  )
}

export default ChatHeader
