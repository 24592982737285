import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import ProtectedRoute from 'commons/protected-route'
import ROUTING_PATH from 'constants/route'
import Action from 'pages/action'
import ContractList from 'pages/contract'
import AtHomeDetail from 'pages/contract/athome'
import ChatPannel from 'pages/contract/chat'
import ContractDetail from 'pages/contract/detail'
import ForgotPassword from 'pages/forgot-password'
import Home from 'pages/home'
import AgentLogin from 'pages/login/agent'
import OwnerLogin from 'pages/login/owner'
import NotFound from 'pages/not-found'
import EmailSetting from 'pages/setting/email'
import EmailSettingDone from './pages/setting/email-done'
import PasswordSetting from 'pages/setting/passwrod'
import SuumoDetail from 'pages/contract/suumo'
import ComparableSales from './pages/contract/comparable-sales'
import NotificationSetting from './pages/setting/notification'
import AuthenticationSetting from 'pages/setting/authentication'
import Other from './pages/contract/other'
import ViewingRequest from './pages/contract/other/viewing-request'
import OtherInquery from './pages/contract/other/other-inquiry'
import PurchaseApplication from './pages/contract/other/purchase-application'
import DocumentRequest from './pages/contract/other/document-request'
import SignInLink from './pages/signinlink/signinlink'
import Help from './pages/signinlink/help'
import ActiveInventories from './pages/contract/active-inventories'
import SuccessfullyConcludedDeals from 'pages/contract/successfully-concluded-deals'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* 共通 */}
        <Route
          path={ROUTING_PATH.A0101}
          element={<Navigate to={ROUTING_PATH.B0101} />}
        />
        <Route path="*" element={<NotFound />} />
        {/* 売り主向け */}
        <Route path={ROUTING_PATH.B0101} element={<OwnerLogin />} />
        <Route path={ROUTING_PATH.B0102} element={<ForgotPassword />} />
        <Route path={ROUTING_PATH.B0103} element={<Action />} />
        <Route path={ROUTING_PATH.OwnerSignInLink} element={<SignInLink />} />
        <Route path={ROUTING_PATH.OwnerSignInLinkHelp} element={<Help />} />
        <Route
          path={ROUTING_PATH.B0201}
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.B0301}
          element={
            <ProtectedRoute>
              <ContractList />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.B0302}
          element={
            <ProtectedRoute>
              <ContractDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.B0303}
          element={
            <ProtectedRoute>
              <ChatPannel />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.B0304}
          element={
            <ProtectedRoute>
              <SuumoDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.B0305}
          element={
            <ProtectedRoute>
              <AtHomeDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.B0311}
          element={
            <ProtectedRoute>
              <ComparableSales />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.B0401}
          element={
            <ProtectedRoute>
              <EmailSetting />
            </ProtectedRoute>
          }
        />
        <Route path={ROUTING_PATH.B0402} element={<EmailSettingDone />} />
        <Route
          path={ROUTING_PATH.B0403}
          element={
            <ProtectedRoute>
              <PasswordSetting />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.B0404}
          element={
            <ProtectedRoute>
              <NotificationSetting />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.B0405}
          element={
            <ProtectedRoute>
              <AuthenticationSetting />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTING_PATH.OwnerContractOther}
          element={
            <ProtectedRoute>
              <Other />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTING_PATH.OwnerContractOtherViewingRequest}
          element={
            <ProtectedRoute>
              <ViewingRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.OwnerActiveInventories}
          element={
            <ProtectedRoute>
              <ActiveInventories />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.OwnerSuccessfullyConcludedDeals}
          element={
            <ProtectedRoute>
              <SuccessfullyConcludedDeals />
            </ProtectedRoute>
          }
        />

        {/* エージェント向け */}
        <Route path={ROUTING_PATH.C0101} element={<AgentLogin />} />
        <Route
          path={ROUTING_PATH.C0201}
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.C0301}
          element={
            <ProtectedRoute>
              <ContractList />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.C0302}
          element={
            <ProtectedRoute>
              <ContractDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.C0303}
          element={
            <ProtectedRoute>
              <ChatPannel />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.C0304}
          element={
            <ProtectedRoute>
              <SuumoDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.C0305}
          element={
            <ProtectedRoute>
              <AtHomeDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.C0311}
          element={
            <ProtectedRoute>
              <ComparableSales />
            </ProtectedRoute>
          }
        />

        <Route
          path={ROUTING_PATH.AgentContractOther}
          element={
            <ProtectedRoute>
              <Other />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.AgentContractOtherViewingRequest}
          element={
            <ProtectedRoute>
              <ViewingRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.AgentContractOtherDocumentRequest}
          element={
            <ProtectedRoute>
              <DocumentRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.AgentContractOtherPurchaseApplication}
          element={
            <ProtectedRoute>
              <PurchaseApplication />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.AgentContractOtherInquiry}
          element={
            <ProtectedRoute>
              <OtherInquery />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.AgentActiveInventories}
          element={
            <ProtectedRoute>
              <ActiveInventories />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTING_PATH.AgentSuccessfullyConcludedDeals}
          element={
            <ProtectedRoute>
              <SuccessfullyConcludedDeals />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
