import log from 'loglevel'
import { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { proxyLogin, singinForAgent } from 'commons/firebase-auth'
import Page from 'commons/page'
import Block from 'components/block'
import { PageTitle, Text } from 'components/typography'
import { ErrorMsg } from 'constants/message'
import ROUTING_PATH from 'constants/route'
import { useAuthProvider } from 'contexts/auth'
import { useAppDispatch } from 'hooks'
import { setErrors } from 'stores/error'
import { AuthErrorCodes, getAuth, signOut } from 'firebase/auth'
import { UserRole } from 'schema/user'
import request from 'apis/request'
import { JWT_TOKEN_KEY_NAME, LOGIN_USER_MODE } from 'constants/common'
import { LoginUserModeEnum } from 'constants/loginusermode'

const LoginButton = styled.button`
  display: block;
  border: none;
  width: 191px;
  height: 46px;
  margin: 0 auto;
  padding: 0;

  img {
    width: 100%;
  }
`

const AgentLogin = () => {
  const { currentUser, claims } = useAuthProvider()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const handleImpersonation = useCallback(async () => {
    const isImpersonation = searchParams.get('impersonation')
    const accessKey = searchParams.get('key')
    if (isImpersonation && accessKey) {
      try {
        const auth = getAuth()
        await signOut(auth)

        const res = await request<{ token?: string; redirect_url?: string }>({
          method: 'post',
          url: `/agents/impersonation-signin`,
          data: { accessKey },
          requireAuth: false,
        })

        if (res.data.token) {
          const proxyToken = await proxyLogin(res.data.token as string)
          const token = await proxyToken.getIdToken()
          localStorage.setItem(JWT_TOKEN_KEY_NAME, token)
          localStorage.setItem(LOGIN_USER_MODE, LoginUserModeEnum.Impersonation)
          navigate(ROUTING_PATH.C0201)
        } else if (res.data.redirect_url) {
          window.location.href = res.data.redirect_url
        } else {
          throw new Error('予期せぬエラー')
        }
      } catch (e: any) {
        log.error(e)
      }
    }
  }, [searchParams, navigate])

  useEffect(() => {
    handleImpersonation()
  }, [handleImpersonation])

  useEffect(() => {
    if (currentUser && claims && claims?.role === UserRole.AGENT) {
      navigate(ROUTING_PATH.C0201)
    }
  }, [claims, currentUser, navigate])

  const handleSubmit = useCallback(async () => {
    try {
      await singinForAgent()
      navigate(ROUTING_PATH.C0201)
    } catch (e: any) {
      log.error(e)
      if (e.code === AuthErrorCodes.POPUP_CLOSED_BY_USER) {
        dispatch(setErrors({ login: { message: ErrorMsg.FORBIDDEN } }))
      }
    }
  }, [dispatch, navigate])

  return (
    <Page title="ログイン" type="guest">
      <PageTitle>エージェント向けログインページ</PageTitle>
      <Block>
        <LoginButton onClick={handleSubmit} data-testid="social-login">
          <img src="/image/sign-in-google.png" alt="google" />
        </LoginButton>
      </Block>
      <Block>
        <Text size="sm">
          これは売主様と媒介契約を結んだエージェント向けのツールのログインページです。
        </Text>
        <Text size="sm">
          ログインにはTerassのGoogleアカウントを使用してください
        </Text>
      </Block>
    </Page>
  )
}

export default AgentLogin
