import { FirebaseError } from '@firebase/util'
import { yupResolver } from '@hookform/resolvers/yup'
import { AuthErrorCodes } from 'firebase/auth'
import log from 'loglevel'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  getCredential,
  updateAccountEmail,
  reauth,
  sendEmailForVerification,
  signout,
} from 'commons/firebase-auth'
import Page from 'commons/page'
import Button from 'components/button'
import Block from 'components/block'
import { Form, FormControl } from 'components/form'
import { Input } from 'components/input-control'
import { ButtonWrapper } from 'components/layout'
import { Text } from 'components/typography'
import {
  JWT_TOKEN_KEY_NAME,
  LOGIN_USER_MODE,
  UPDATE_MAIL,
} from 'constants/common'
import { ErrorMsg } from 'constants/message'
import { useAuthProvider } from 'contexts/auth'
import { useNavigate } from 'react-router-dom'
import ROUTING_PATH from 'constants/route'
import NoLogoHeader from '../../components/no-logo-header'
import styled from 'styled-components'
import BasePanel from '../../components/panel'

const Panel = styled(BasePanel)`
  background: transparent;
  border: none;
  box-shadow: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 660px;
  margin: 0 auto;
`

const FullButton = styled(Button)`
  width: 100%;
`

const FullInput = styled(Input)`
  width: 100%;
`

interface UpdateEmailProp {
  currentPassword: string
  email: string | null
}

const EmailSetting = () => {
  const { currentUser } = useAuthProvider()
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const schema = yup.object({
    currentPassword: yup.string().required(ErrorMsg.REQUIRED),
    email: yup.string().required(ErrorMsg.REQUIRED),
  })

  const form = useForm<UpdateEmailProp & { rePassword: string }>({
    resolver: yupResolver(schema),
  })

  const handleSubmit = useCallback(
    async (data: UpdateEmailProp) => {
      if (!currentUser || !data.email) {
        return
      }

      if (currentUser?.email === data.email) {
        form.setError('email', { type: 'custom', message: ErrorMsg.SAME_EMAIL })
        form.setValue('currentPassword', '')
        return
      }

      try {
        setLoading(true)
        const credential = getCredential(
          currentUser?.email ?? '',
          data.currentPassword
        )

        await reauth(currentUser, credential).catch((e) => {
          if (e.code === AuthErrorCodes.INVALID_PASSWORD) {
            form.setError('currentPassword', {
              type: 'custom',
              message: ErrorMsg.FAILURE_REAUTH,
            })
            form.setValue('currentPassword', '')
          }
          throw e
        })

        localStorage.setItem(
          UPDATE_MAIL,
          JSON.stringify({
            before: currentUser?.email,
            after: data.email,
          })
        )

        await updateAccountEmail(currentUser, data.email)

        await sendEmailForVerification(currentUser)
        signout().then(() => {
          localStorage.removeItem(LOGIN_USER_MODE)
          localStorage.removeItem(JWT_TOKEN_KEY_NAME)
          navigate(ROUTING_PATH.B0402)
        })
      } catch (e) {
        if (e instanceof FirebaseError) {
          log.error(e)
        }
      }

      form.setValue('currentPassword', '')
      setLoading(false)
    },
    [currentUser, form, navigate]
  )

  return (
    <Page
      title="メールアドレス変更"
      loading={loading}
      customHeader={<NoLogoHeader title="メールアドレス変更" />}
    >
      <Panel>
        <Block>
          <Text>
            現在のパスワードと新しいメールアドレスを入力してください。
          </Text>
        </Block>
        <Block>
          <Form form={form} onSubmit={handleSubmit}>
            <Block>
              <FormControl label="現在のパスワード" name="currentPassword">
                <FullInput
                  type="password"
                  placeholder="●●●●●●●●●"
                  aria-label="現在のパスワード"
                />
              </FormControl>
              <FormControl label="新しいメールアドレス" name="email">
                <FullInput
                  type="email"
                  placeholder="abc@example.com"
                  maxLength={200}
                  aria-label="新しいメールアドレス"
                />
              </FormControl>
            </Block>
            <ButtonWrapper>
              <FullButton>変更する</FullButton>
            </ButtonWrapper>
          </Form>
        </Block>
      </Panel>
    </Page>
  )
}

export default EmailSetting
