import { useCallback, useState } from 'react'
import { slide as Slide } from 'react-burger-menu'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { signout } from 'commons/firebase-auth'
import {
  JWT_TOKEN_KEY_NAME,
  LOGIN_USER_MODE,
  PRIVACY_POLICY_URL,
  TERMS_URL,
} from 'constants/common'
import ROUTING_PATH from 'constants/route'
import { useAuthProvider } from 'contexts/auth'
import { UserRole } from 'schema/user'
import getLayer from 'styles/layer'

const Container = styled.div`
  .bm-overlay {
    z-index: ${getLayer('menu')} !important;
  }

  .bm-burger-button {
    position: fixed;
    height: 16px;
    width: 22px;
    right: 22px;
    top: 32px;
  }

  .bm-cross-button {
    width: 18px !important;
    height: 18px !important;
    right: 23px !important;
    top: 32px !important;
  }

  .bm-menu {
    margin-top: 80px;
    border-top: 1px solid ${(p) => p.theme.GROBAL_MENU_BORDER};
    background-color: ${(p) => p.theme.BACKGROUND_SURFACE};
  }

  .bm-item {
    position: relative;
    display: block;
    width: 100%;
    padding: 18px 20px;
    background-color: inherit;
    border: none;
    border-bottom: 1px solid ${(p) => p.theme.GROBAL_MENU_BORDER};
    box-sizeing: border-box;
    color: ${(p) => p.theme.FONT_COLOR};
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
    text-decoration: none;

    img {
      display: inline-block;
      margin-left: 10px;
    }
  }

  @media only screen and (max-width: ${(p) => p.theme.vp}px) {
    .bm-burger-button {
      top: 20px;
    }

    .bm-cross-button {
      top: 22px !important;
    }

    .bm-menu {
      margin-top: 60px;
    }
  }
`

const GlobalMenu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { currentUser, claims } = useAuthProvider()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleStateChange = useCallback((state: any) => {
    setIsOpen(state.isOpen)
  }, [])

  const handleSignOut = useCallback(() => {
    signout().then(() => {
      localStorage.removeItem(LOGIN_USER_MODE)
      localStorage.removeItem(JWT_TOKEN_KEY_NAME)
      if (pathname.includes('owner')) {
        navigate(ROUTING_PATH.B0101)
      } else if (pathname.includes('agent')) {
        navigate(ROUTING_PATH.C0101)
      } else {
        navigate(ROUTING_PATH.A0101)
      }
    })
    handleClose()
  }, [pathname, handleClose, navigate])

  return (
    <Container>
      <Slide
        right
        width={238}
        isOpen={isOpen}
        onStateChange={(state: { isOpen: boolean }) => handleStateChange(state)}
        customBurgerIcon={
          !isOpen ? <img src="/image/menu.svg" alt="menu" /> : false
        }
        customCrossIcon={<img src="/image/close.svg" alt="menu" />}
      >
        {!currentUser && (
          <Link to={ROUTING_PATH.A0101} onClick={() => handleClose()}>
            ログイン
          </Link>
        )}
        {currentUser && claims?.role === UserRole.OWNER && (
          <Link to={ROUTING_PATH.B0201} onClick={() => handleClose()}>
            物件一覧
          </Link>
        )}
        {currentUser && claims?.role === UserRole.AGENT && (
          <Link to={ROUTING_PATH.C0201} onClick={() => handleClose()}>
            物件一覧
          </Link>
        )}
        {currentUser && claims?.role === UserRole.OWNER && (
          <Link to={ROUTING_PATH.B0404} onClick={() => handleClose()}>
            通知設定
          </Link>
        )}
        {currentUser && claims?.role === UserRole.OWNER && (
          <Link to={ROUTING_PATH.B0405} onClick={() => handleClose()}>
            ログイン設定
          </Link>
        )}
        {currentUser && claims?.role === UserRole.AGENT && (
          <a
            href="https://www.notion.so/terass/Terass-Portal-9c3da7651b5e444c8dabbe617e22aad6?pvs=4#706e3f59e7ad4273b95f81909ffc5f4b"
            onClick={() => handleClose()}
            target="_blank"
            rel="noreferrer"
          >
            FAQ
            <img src="/image/outside.svg" alt="FAQ" />
          </a>
        )}

        {currentUser && claims?.role === UserRole.OWNER && (
          <a
            href="https://terassinc.notion.site/Terass-Portal-SUUMO-3d4794cc89dc45cba4c085cbb9abc55a"
            onClick={() => handleClose()}
            target="_blank"
            rel="noreferrer"
          >
            SUUMOデータ確認方法
            <img src="/image/outside.svg" alt="SUUMOデータ確認方法" />
          </a>
        )}
        <a
          href={PRIVACY_POLICY_URL}
          onClick={() => handleClose()}
          target="_blank"
          rel="noreferrer"
        >
          プライバシーポリシー
          <img src="/image/outside.svg" alt="プライバシーポリシー" />
        </a>
        <a
          href={TERMS_URL}
          onClick={() => handleClose()}
          target="_blank"
          rel="noreferrer"
        >
          利用規約
          <img src="/image/outside.svg" alt="利用規約" />
        </a>
        {currentUser && (
          <button onClick={() => handleSignOut()}>ログアウト</button>
        )}
      </Slide>
    </Container>
  )
}

export default GlobalMenu
