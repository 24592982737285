const theme = {
  BACKGROUND_BASE: '#F8F8F8',
  BACKGROUND_SURFACE: '#FFFFFF',
  BACKGFOUND_TEXTAREA: '#E8E8E8;',
  BACKGROUND_IMPERSONATION: '#5F94A3;',

  MAIN: '#F39800',
  WHITE: '#FFFFFF',
  BLACK: 'black',
  LINK: '#3354AA',
  RED: '#DD0000',
  GROBAL_MENU_BORDER: '#D9D9D9',
  BORDER: '#C3C0BA',
  SECONDARY: '#808080',
  THERTARY: 'transparent',
  NOTIFICATION: '#FFE4B7',
  DISABLED: '#F9C270',
  FONT_COLOR: '#463E28',
  ERROR_FONT_COLOR: '#DE1212',
  ERROR_BG_COLOR: '#FFD5C8',
  PORTAL_SITE_1: '#6FB82B',
  PORTAL_SITE_2: '#ED6103',
  PORTAL_SITE_3: '#CA0A37',

  GRAPH_COLOR_01: '#503200',
  GRAPH_COLOR_02: '#794B00',
  GRAPH_COLOR_03: '#A16500',
  GRAPH_COLOR_04: '#CA7F00',
  GRAPH_COLOR_05: '#F39800', // 基準色
  GRAPH_COLOR_06: '#FFB12E',
  GRAPH_COLOR_07: '#FFC25C',
  GRAPH_COLOR_08: '#FFD38A',
  GRAPH_COLOR_09: '#FFE4B7',
  GRAPH_COLOR_10: '#FFF5E5',
  GRAPH_COLOR_11: '#C8C8C8',
  GRAPH_COLOR_12: '#008DF3',

  CHAT_SELF: '#EAA73D',
  CHAT_OTHER: '#E8E8E8',
  UNREAD: '#FF0000',
  OVERLAY: 'rgba(37, 37,41, 0.95)',
  DIALOG_OVERLAY: 'rgba(217, 217, 217, 0.7)',

  vp: 1200,

  FONT: `"Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif`,
}

export type ThemeType = typeof theme

export default theme
